import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'

import SEO from "../components/seo"
import Intro from "../components/intro/intro"
import ApartmentServices from "../components/apartment-services/apartment-services"
import Contacts from "../components/contacts/contacts"
import ApartmentGallery from "../components/apartment-gallery/apartment-gallery"
import HevizImgs from "../components/heviz-imgs/heviz-imgs"

const IndexPage = () => {
  return (
    <div>
      <SEO />
      <Intro />
      <ApartmentServices />
      <ApartmentGallery />
      <Contacts />
      <HevizImgs />
    </div>
  )
}

export default IndexPage